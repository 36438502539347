import React from 'react';
import logo from '../assets/images/visage-logo.svg';

const navLinks = [
  { text: 'Home', url: '/' },
  { text: 'Scan', url: '/scan' },
  { text: 'About', url: '/' },
  { text: 'Profile', url: '/' },
  { text: 'Login', url: '/login' },
];

function Navbar() {
  return (
    <div className="d-flex flex-row p-3 align-items-center">
      <div>
        <img src={logo} alt="Logo" width="104px" height="39px" />
      </div>
      <ul className="d-flex list-unstyled align-items-center px-5">
        {navLinks.map((link, index) => (
          <li className="mx-4" key={index}>
            <a href={link.url} style={{ color: 'black', textDecoration: 'none' }}>
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Navbar;
