import React from "react"
import Signup from "./Signup"
import Profile from "./Profile"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./ForgotPassword"
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UpdateProfile from "./UpdateProfile"
import LandingPage from "./LandingPage"
import Navbar from "./Navbar"
import Scan from "./Scan"

function App() {
  return (
    <React.Fragment className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="w-100">
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<PrivateRoute><Profile/></PrivateRoute>} />
              <Route path="/update-profile" element ={<PrivateRoute><UpdateProfile/></PrivateRoute>} />
              <Route path="/landing-page" element={<LandingPage />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/scan" element={<Scan />} />
            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </React.Fragment>
  )
}

export default App
