import React, { useState, useEffect, useRef } from 'react';
import * as faceapi from 'face-api.js';
import './app.css';
import NewPost from './NewPost';
import GradientContainer from './GradientCard';
import Navbar from './Navbar';

function App() {
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [faceShape, setFaceShape] = useState("");

  const videoRef = useRef();
  const videoHeight = 480;
  const videoWidth = 640;
  const canvasRef = useRef();

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';

      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]);

      setModelsLoaded(true);
    };
    loadModels();
  }, []);

  const startVideo = () => {
    setCaptureVideo(true);
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error('error:', err);
      });
  };

  const handleVideoOnPlay = () => {
    setInterval(async () => {
      if (canvasRef && canvasRef.current) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current);
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };

        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        canvasRef &&
          canvasRef.current &&
          canvasRef.current.getContext('2d').clearRect(0, 0, videoWidth, videoHeight);
        canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        canvasRef && canvasRef.current && faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
      }
    }, 100);
  };

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
  };

  const loadingTime = async (time) => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, time));
    setIsLoading(false);
  }

  const captureImage = async () => {
    setIsLoading(true);
    closeWebcam();

    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      // Draw the silhouette or overlay image
      const overlayImage = new Image();
      overlayImage.src = 'path_to_overlay_image.png'; // Replace with the path to your overlay image
      ctx.drawImage(overlayImage, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL('image/jpeg');
      setImage({ url: dataURL });

      // Wait for a few seconds (simulate loading)
      await new Promise((resolve) => setTimeout(resolve, 3500));
      setIsLoading(false);
    }
  };

  return (
    <GradientContainer>
      <Navbar />
      <div style={{ height: "75vh" }}>
        {isLoading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
        {
        // faceShape ? (
        //   <div className='d-flex justify-content-center' style={{ color: 'black' }}>
        //     <div className='d-flex flex-row'>
        //       <div className='border'>
        //         <h3>Your face shape is: <b>{faceShape}</b></h3>
        //       </div>
        //       <div>
        //         fafklafklkl
        //       </div>
        //     </div>
        //   </div>
        // ) :
         image ? (
          <NewPost image={image} saveFaceShape={(e) => {
            setFaceShape(e);
            // loadingTime(2000);
          }} 
          />
        ) : captureVideo ? (
          modelsLoaded ? (
            <div className='d-flex justify-content-center flex-column'>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                <video ref={videoRef} height={videoHeight} width={videoWidth} onPlay={handleVideoOnPlay} style={{ borderRadius: '10px' }} />
                <canvas ref={canvasRef} style={{ position: 'absolute' }} />
              </div>
              <button onClick={captureImage} style={{ width: "10rem", alignSelf: "center", color: "black", backgroundColor: "white", borderRadius: "20px" }}>
                Capture
              </button>
            </div>
          ) : (
            <div>loading...</div>
          )
        ) : (
          <div style={{ textAlign: 'center', padding: '10px' }}>
            <button
              onClick={startVideo}
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                color: 'black',
                padding: '15px',
                fontSize: '25px',
                border: 'none',
                borderRadius: '10px',
              }}
            >
              Start Scan
            </button>
          </div>
        )}
      </div>
    </GradientContainer>
  );
}

export default App;
