import React from 'react'
import GradientCard from './GradientCard'
import RJ from "../assets/images/rj.png"
import Arrow from "../assets/images/arrow.svg"
import Person from "../assets/images/person.svg"
import Measure from "../assets/images/measure.svg"
import Glasses from "../assets/images/glasses.svg"
import LeftRectangle from "../assets/images/left_rectangle-cropped.svg"
import RightRectangle from "../assets/images/right_rectangle-cropped.svg"
import FacialScanning from "../assets/images/facial_scanning.svg"
import Abacus from "../assets/images/abacus.svg"
import OtherGlasses from "../assets/images/other-glasses.svg"
import { Link } from "react-router-dom"
import Navbar from './Navbar'

export default function LandingPage() {
    const backgroundStyle = {
        backgroundColor: 'white',
        padding: '1px',
        width: '100vw',
        height: '100vh',
        margin: 0,
        alignItems: 'center',
        justifyContent: 'center'
    }

    const cardStyle = {
        backgroundColor: '#E5E4FD',
        height: '300px',
        borderRadius: '16px',
        margin: '5px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    
    const imageStyle = {
        width: '60%',
        height: '60%',
    }

    return (
        <div style={backgroundStyle}>
            <GradientCard>
                <Navbar />
                <div className='d-flex'>
                    <div className='d-flex justify-content-center flex-column ps-4'>
                        <h1 style={{ fontSize: "3.8rem", color: "black", fontWeight: "bold" }}>3D Face Scanning for your 
                        perfect pair of glasses.</h1>
                        <p className="mt-4"  style={{ fontSize: "1.5rem", color: "black", width: "60%" }}>Scan your face, 
                        measure your unique features, and match with glasses that fit you perfectly.</p>
                        <h4 style={{ fontSize: "2rem", color: "black", fontWeight: "250" }}>Ready to find your perfect fit?
                        <a href="/scan">
                        <img className='ps-4' src={Arrow} alt="navigation arrow" width="auto" height="50px"/>
                        </a></h4>

                    </div>
                    <div>
                        <img src={RJ} alt="rj" width="auto" height="650px" />
                    </div>
                </div>
            </GradientCard>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card" style={cardStyle}>
                            <img src={Person} alt="person" style={imageStyle}/>
                        </div>
                        <h4 style={{ textAlign: 'center' }}>Scan your face with a webcam.</h4>
                    </div>
                    <div class="col-md-4">
                        <div class="card" style={cardStyle}>
                            <img src={Measure} alt="measure" style={imageStyle}/>
                        </div>
                        <h4 style={{ textAlign: 'center' }}>We'll calculate your dimensions.</h4>
                    </div>
                    <div class="col-md-4">
                        <div class="card" style={cardStyle}>
                            <img src={Glasses} alt="glasses" style={imageStyle}/>
                        </div>
                        <h4 style={{ textAlign: 'center' }}>Find glasses that fit your face.</h4>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center mt-5'>
                <h1 style={{ fontWeight: '530', textDecoration: 'underline', letterSpacing: '12px' }}>OUR VISION</h1>
            </div>
            <div className='d-flex flex-row'>
                <div className='w-50 align-items-center'>
                    <img src={LeftRectangle} alt="Decorative half circle left" width="100%" height="90%"/>
                    <img src={FacialScanning} alt="Person being scanned" width="60%" height="60%" style={{position: 'absolute', 
                    top: 1450, left: 25, zIndex: 1}}/>
                </div>
                <div className='d-flex justify-content-center w-50 align-items-center flex-column' style={{padding: '9rem',
                    lineHeight: '2rem'}}>
                    <div>
                        <h1 style={{ fontWeight: '550' }}>Advanced Facial Scanning</h1>
                        <p>Our 3D facial scanning technology employs advanced back-end coding and computational 
                            algorithms to precisely capture and process facial data. By meticulously measuring an 
                            individual's unique facial features, we generate accurate measurements, facilitating 
                            customized eyewear recommendations. Our technological proficiency ensures a seamless 
                            fusion of form and function, providing customers with eyeglasses that perfectly
                            align with their distinct facial attributes.</p>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-row'>
                <div className='d-flex justify-content-center w-50 align-items-center flex-column' style={{padding: '9rem',
                    lineHeight: '2rem'}}>
                    <div>
                        <h1 style={{ fontWeight: '550' }}>12 Key Measurements</h1>
                        <p>For the perfect pair of glasses, measuring 12 key facial dimensions ensures both comfort and aesthetic 
                            harmony. These include factors like facial symmetry, nose bridge width, and precise pupillary distance, 
                            which are vital for optical alignment and fit. These interconnected dimensions create the foundation 
                            for glasses that not only fit flawlessly but also enhance the overall aesthetic, while aiding 
                            in eye health.</p>
                    </div>
                </div>
                <div className='w-50 align-items-center'>
                    <img src={RightRectangle} alt="Decorative half circle right" width="100%" height="90%"/>
                    <img src={Abacus} alt="Person being scanned" width="80%" height="80%" style={{position: 'absolute', 
                    top: 2175, right: -175, zIndex: 1}}/>
                </div>
            </div>
            <div className='d-flex flex-row'>
                <div className='w-50 align-items-center'>
                    <img src={LeftRectangle} alt="Decorative half circle left" width="90%" height="90%"/>
                    <img src={OtherGlasses} alt="Person being scanned" width="40%" height="40%" style={{position: 'absolute', 
                    top: 3120, left: 185, zIndex: 1}}/>
                </div>
                <div className='d-flex justify-content-center w-50 align-items-center flex-column' style={{padding: '9rem',
                    lineHeight: '2rem'}}>
                    <div>
                        <h1 style={{ fontWeight: '550' }}>Innovating the Eyewear Industry for a Global Audience</h1>
                        <p>Our mission at Visage is to democratize access to biometric face measurements, ensuring that 
                            everyone can enjoy eyewear that is not only aesthetically pleasing but also comfortable and
                            promotes eye health. Our vision extends beyond simply selling our own glasses; we aim to 
                            revolutionize the eyewear industry by making custom-tailored glasses a standard offering for 
                            all. By providing easy access to precise biometric data, we empower eyewear companies to 
                            create personalized and perfectly fitting glasses for their customers. We believe that 
                            personalized eyewear should be a norm, not a luxury, and we are committed to driving this 
                            transformative change within the industry to benefit individuals worldwide.</p>
                    </div>
                </div>
            </div>
            <div className='pb-5'>
                <GradientCard>
                    <div className='d-flex justify-content-center flex-column' style={{ textAlign: 'center' }}>
                        <h1 style ={{ color: 'black', fontSize: '60px', fontWeight: '510', lineHeight: '3rem' }}>THANK YOU</h1>
                        <h1 style ={{ color: 'black', fontSize: '60px', fontWeight: '510', lineHeight: '3rem' }}>FOR VISITING!</h1>
                        <a href="/landing-page" style={{ color: 'black' }}>
                            <p style={{ textDecoration: 'underline', fontSize: '22px' }}>Click to Scroll Back to the Top</p>
                        </a>
                    </div>
                </GradientCard>
            </div>
        </div>
    )
}
