import { useEffect, useRef, useState } from "react";
import * as faceapi from "face-api.js";
import './app.css';
import Heart from "../assets/images/heart.svg"
import Arrow from "../assets/images/arrow.svg"
import Square from "../assets/images/square.png";
import Oval from "../assets/images/oval.png";
import React from "react";
import RectangleFrames from "../assets/images/rectangle-frames.svg"
import RoundedFrames from "../assets/images/rounded-frames.svg";
import Frame1 from "../assets/images/frames1.png"
import Frame2 from "../assets/images/frames2.png"
import Frame3 from "../assets/images/frames3.png"

const NewPost = ({ image, saveFaceShape }) => {
  const { url, width, height } = image;
  const [faceShape, setFaceShape] = useState("");
  const [eyeDistance, setEyeDistance] = useState(0);
  const [noseBridge, setNoseBridge] = useState(0);
  const [earNumber, setEarNumber] = useState(0);
  const [templeLength, setTempleLength] = useState(0);
  const [topFaceWidth, setTopFaceWidth] = useState(0);
  const [jawWidth, setJawWidth] = useState(0);
  const [faceHeightRatio, setFaceHeightRatio] = useState(0);
  const [ratio, setRatio] = useState(0);
  const imgRef = useRef();
  const canvasRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const loadingTime = async (time) => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, time));
    setIsLoading(false);
  }

  const cardStyle = {
    backgroundColor: 'white',
    height: '150px',
    borderRadius: '16px',
    margin: '3px',
    justifyContent: 'center',
    alignItems: 'center'
  }
  const imageStyle = {
    width: '60%',
    height: 'auto',
  }


  const handleImage = async () => {
    const detection = await faceapi.detectSingleFace(
      imgRef.current,
      new faceapi.TinyFaceDetectorOptions()
    ).withFaceLandmarks();

    if (detection) {
      const { landmarks } = detection;
      determineFaceShape(landmarks);
    }
  };

  const determineFaceShape = (landmarks) => {
    const landmarkPositions = landmarks.positions;
  
    // Calculate distances and ratios based on facial landmarks
    const [leftEye, rightEye] = [landmarks.getLeftEye(), landmarks.getRightEye()];
    const chin = landmarks.getJawOutline();
    const nose = landmarks.getNose();
    const leftBrow = landmarks.getLeftEyeBrow();
    const rightBrow = landmarks.getRightEyeBrow();
    const browWidth = rightBrow[rightBrow.length - 1]._x - leftBrow[0]._x
    const noseHeight = nose[nose.length - 1].y - nose[0].y;
    const distanceBetweenEyes = rightEye[0]._x - leftEye[leftEye.length - 1]._x
    const topOfFaceWidth = chin[chin.length - 1]._x - chin[0]._x;
    const bottomOfFaceWidth = chin[11]._x - chin[5]._x
    const faceWidth = Math.max(topOfFaceWidth, bottomOfFaceWidth) // Measure from left eye to right eye
    const topBrow = Math.max(leftBrow[2]._y, rightBrow[2]._y);
    const faceHeight = topBrow
    const estimatedFaceHeight = topBrow + noseHeight

    setTopFaceWidth(topOfFaceWidth / bottomOfFaceWidth);
    setJawWidth(bottomOfFaceWidth / topOfFaceWidth);
    setFaceHeightRatio(faceHeight / faceWidth);

    setRatio(Math.round((topFaceWidth + jawWidth + faceHeight) * 100) / 100)

    setEarNumber(chin[0]._y / chin[chin.length - 1]._y);
    setNoseBridge(noseHeight);
    setEyeDistance(distanceBetweenEyes);
    setTempleLength(browWidth);

  
    // Define thresholds for each face shape
    const squareThreshold = 1.1;
    console.log(faceShape)
    console.log(topOfFaceWidth);
    console.log(bottomOfFaceWidth);
    console.log("FACE HEIGHT : " + faceHeight);
    console.log("FACE WIDTH : " + faceWidth);
    console.log(estimatedFaceHeight);
    console.log(topBrow);

    if (faceHeight / faceWidth < squareThreshold) {
      setFaceShape("Square");
    } else if (faceHeight / faceWidth > squareThreshold && topOfFaceWidth / bottomOfFaceWidth > 1.2) {
      setFaceShape("Oval");
    } else if (faceHeight / faceWidth > squareThreshold && topOfFaceWidth / bottomOfFaceWidth <= 1.2) {
      setFaceShape("Oblong");
    } else {
      setFaceShape("Diamond");
    }
  };

  useEffect(() => {
    const loadModels = async () => {
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
        faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        faceapi.nets.faceExpressionNet.loadFromUri("/models"),
      ]);

      handleImage();
    };

    if (imgRef.current) {
      loadModels();
    }
  }, []);

  return (
    <div className="container h-100 w-100">
        {isLoading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
        {goNext ? (
          <div className="d-flex h-100 w-100 flex-column mx-5">
            <h1 style={{ color: 'black' }}>Here are some frame styles that fit your face best.</h1>
          <div className="d-flex h-100 w-100 flex-row">
            <div className="" style={{ width: "35%" }}>
              {/* <canvas
                onMouseEnter={null}
                ref={canvasRef}
                width={width}
                height={height}
              /> */}
              <div className="" style={{ backgroundColor: 'white', width: "100%", borderRadius: "20px" }}>
                <div className="d-flex flex-row w-100 mt-3 pt-3 ms-4">
                  <div className="d-flex justify-content-start w-100 flex-row">
                    <div>
                      <h1 style={{ color: 'black' }}>Your Results </h1>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-start w-100 mx-4">
                  <button style={{ 
                    color: 'black',
                    backgroundColor: 'white', 
                    borderRadius: '25px', 
                    border: "1px", 
                    borderColor: 'black', 
                    borderStyle: 'solid', 
                    margin: "4px"
                    }}
                  >
                    {faceShape}
                  </button>
                  <button style={{ 
                    color: 'black',
                    backgroundColor: 'white', 
                    borderRadius: '25px', 
                    border: "1px", 
                    borderColor: 'black', 
                    borderStyle: 'solid',
                    margin: "4px"
                    }}
                  >
                    {ratio} Ratio
                  </button>
                </div>

                <div className="mx-4 my-2">
                  <h4 style={{ color: 'black' }}>Face Shape Dimensions</h4>
                  <hr style={{ color: 'black' }} />

                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Top Face Width Ratio</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{topFaceWidth}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Jaw Width Ratio</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{jawWidth}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Face Height/Width Ratio</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{faceHeightRatio}</p>
                  </div>
                </div>

                <div className="mx-4 my-2">
                  <h4 style={{ color: 'black' }}>Fitment Measurements</h4>
                  <hr style={{ color: 'black' }} />

                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Ear Offset</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{earNumber}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Nose Bridge </p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{noseBridge}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Eye Spacing</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{eyeDistance}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Temple Length</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{templeLength}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column ms-3 mt-3" style={{ width: "65%" }}>
              <div className="h-50 w-100 d-flex flex-row justify-content-center" style={{ backgroundColor: 'white', borderRadius: "25px", height: "100%", alignItems: "center" }}>
                <div className="h-100 d-flex flex-column mx-3 align-content-center">
                  <h2 className="mt-5 me-4" style={{ color: "black" }}>{faceShape == "Square" ? "Oval Frames" : "Rectangle Frames"}</h2>
                  <hr style={{ color: 'black' }} />
                  <h5 style={{ color: 'black' }}>These frames complement <b>{faceShape}</b> faces.</h5>
                </div>
                <div>
                <img src={faceShape == "Oval" ? RectangleFrames : RoundedFrames} alt="frames" width="250px" height="auto" />
                </div>
                
              </div>
              <h3 className="mt-2" style={{ color: 'black' }}>Recommended For You</h3>
              <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card" style={cardStyle}>
                            <img src={Frame3} alt="person" style={imageStyle}/>
                            <a href="https://www.ebay.com/itm/334108433467" style={{ textAlign: 'center' }}>Warby Parker</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card" style={cardStyle}>
                            <img src={Frame2} alt="measure" style={imageStyle}/>
                            <a href="https://www.farfetch.com/shopping/women/ray-ban-oval-frame-glasses-item-18209434.aspx" style={{ textAlign: 'center' }}>Ray-Ban</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card" style={cardStyle}>
                            <img src={Frame1} alt="glasses" style={imageStyle}/>
                            <a href="https://www.americasbest.com/guess-2927/p/329797" style={{ textAlign: 'center' }}>Guess</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          </div>
        ) : 
        (
          <React.Fragment>
            <div className="left h-100 w-50">
              <img style={{ height: 0 }} ref={imgRef} crossOrigin="anonymous" src={url} alt="" />
              {/* <canvas
                onMouseEnter={null}
                ref={canvasRef}
                width={width}
                height={height}
              /> */}
              <div className="" style={{ backgroundColor: 'white', width: "100%", borderRadius: "20px" }}>
                <div className="d-flex flex-row w-100 m-4">
                  {faceShape == "Square" ? (
                    <img src={Square} alt="square" height="100px" />
                  ) : faceShape == "Oval" ? (
                    <img src={Oval} alt="oval" height="100px" />
                  ) : (
                    <img src={Heart} alt="heart" height="100px" />
                  )}
                  <div className="d-flex justify-content-center w-100 flex-row">
                    <div>
                      <h1 style={{ color: 'black' }}>Your Face Shape is: </h1>
                      <div className="d-flex justify-content-center">
                        <b style={{ color: 'black', fontSize: "3rem" }}>{faceShape}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-evenly w-100 my-3">
                  <button style={{ 
                    color: 'black',
                    backgroundColor: 'white', 
                    borderRadius: '25px', 
                    border: "1px", 
                    borderColor: 'black', 
                    borderStyle: 'solid' 
                    }}
                  >
                    {faceShape}
                  </button>
                  <button style={{ 
                    color: 'black',
                    backgroundColor: 'white', 
                    borderRadius: '25px', 
                    border: "1px", 
                    borderColor: 'black', 
                    borderStyle: 'solid' 
                    }}
                  >
                    {ratio} Ratio
                  </button>
                  <button style={{ 
                    color: 'black',
                    backgroundColor: 'white', 
                    borderRadius: '25px', 
                    border: "1px", 
                    borderColor: 'black', 
                    borderStyle: 'solid' 
                    }}
                  >
                    Share
                  </button>
                  <button style={{ 
                    color: 'black',
                    backgroundColor: 'white', 
                    borderRadius: '25px', 
                    border: "1px", 
                    borderColor: 'black', 
                    borderStyle: 'solid' 
                    }}
                  >
                    Download
                  </button>
                </div>

                <div className="mx-4 my-2">
                  <h4 style={{ color: 'black' }}>Face Shape Dimensions</h4>
                  <hr style={{ color: 'black' }} />

                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Top Face Width Ratio</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{topFaceWidth}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Jaw Width Ratio</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{jawWidth}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Face Height/Width Ratio</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{faceHeightRatio}</p>
                  </div>
                </div>

                <div className="mx-4 my-2">
                  <h4 style={{ color: 'black' }}>Fitment Measurements</h4>
                  <hr style={{ color: 'black' }} />

                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Ear Offset</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{earNumber}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Nose Bridge </p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{noseBridge}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Eye Spacing</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{eyeDistance}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ color: 'black', fontSize: "16px" }}>Temple Length</p>
                    <p style={{ color: 'black', fontSize: "16px" }}>{templeLength}</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="right">
              <div className="d-flex justify-content-start flex-column">
                <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: "4rem" }}>Results</h1>
                <div className="d-flex flex-row">
                  <h4 style={{ color: 'black', fontSize: "2rem" }}>Explore frames</h4>
                  <img 
                    className='ps-4' 
                    src={Arrow} 
                    alt="navigation arrow" 
                    width="auto" 
                    height="50px"
                    onClick={() => {
                      loadingTime(4000);
                      setGoNext(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      
    </div>
  );
};

export default NewPost;
