import React from 'react';
import './GradientCard.css'; // We will create this CSS file

function GradientContainer({ children }) {
  return (
    <div className="gradient-container m-3">
      {children}
    </div>
  );
}

export default GradientContainer;
